import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { money } from 'sf-modules';
import Badge from '../Badge';

ProductPriceForQuantity.propTypes = {
    wrapperClassName: PropTypes.string,
    pricesWrapperClassName: PropTypes.string,
    discountsWrapperClassName: PropTypes.string,
    context: PropTypes.oneOf([
        'pos',
        'cart',
        'checkout',
        'success',
        'profileOrder',
        'hit',
        'product-page',
        'modal',
        'default',
    ]),
    product: PropTypes.object,
    hideDiscounts: PropTypes.bool,
};

ProductPriceForQuantity.defaultProps = {
    context: 'default',
    hideDiscounts: false,
};

export default function ProductPriceForQuantity({
    wrapperClassName,
    pricesWrapperClassName,
    discountsWrapperClassName,
    hideDiscounts,
    context,
    product,
}) {
    if (!product?.catalogPrice) return <></>;

    if (product.isGift) {
        let nbGifts = product.productsGifted || 1;
        return (
            <span className='font-semibold font-body text-normal leading-tight text-ui-gray-900'>
                Regalo
                <span className='ml-1 text-small font-body-regular text-ui-gray-500'>
                    ({nbGifts} unidad{nbGifts > 1 && 'es'})
                </span>
            </span>
        );
    }

    let regularPricePerUnit;
    let priceToPayPerUnit;
    let totalPriceToPay;
    let pricePerQuantityUnit;
    let sellingValue = Number(product.sellingValue);
    let refinedSellingValue = Number.isInteger(sellingValue)
        ? sellingValue
        : sellingValue.toFixed(1);
    let productQuantityForPrice =
        product.sellingUnit && `(${refinedSellingValue} ${product.sellingUnit})`;
    let isBuyingContext = false;

    switch (context) {
        case 'pos':
        case 'cart':
        case 'checkout':
            isBuyingContext = true;
            totalPriceToPay = product.totalPrice;
            priceToPayPerUnit = totalPriceToPay / product.billedQuantity;
            regularPricePerUnit =
                priceToPayPerUnit < product.catalogPrice.price ? product.catalogPrice.price : null;
            break;

        case 'success':
        case 'profileOrder':
            isBuyingContext = true;
            totalPriceToPay = product.paidPricing.totalPrice;
            priceToPayPerUnit = totalPriceToPay / product.billedQuantity;
            regularPricePerUnit =
                priceToPayPerUnit < product.paidPricing.priceUndiscounted
                    ? product.paidPricing.priceUndiscounted
                    : null;
            break;

        case 'product-page':
            totalPriceToPay = null;
            priceToPayPerUnit = product.catalogPrice.discountedPrice;
            regularPricePerUnit =
                priceToPayPerUnit < product.catalogPrice.price ? product.catalogPrice.price : null;
            pricePerQuantityUnit =
                refinedSellingValue &&
                `${money.formatMoney(
                    product.catalogPrice.discountedPrice / refinedSellingValue
                )} / ${product.sellingUnit}`;
            break;

        default:
            totalPriceToPay = null;
            priceToPayPerUnit = product.catalogPrice.discountedPrice;
            regularPricePerUnit =
                priceToPayPerUnit < product.catalogPrice.price ? product.catalogPrice.price : null;
            productQuantityForPrice =
                product.sellingUnit && refinedSellingValue + ' ' + product.sellingUnit;
            break;
    }

    let saleByWeight = product.sellingType && product.sellingType !== 'por-pieza';
    let showProductQuantityForPrice = !saleByWeight || context === 'default';
    let discount = money.getDiscount(priceToPayPerUnit, regularPricePerUnit);
    let sellingPresentation =
        saleByWeight && refinedSellingValue
            ? ` / ${refinedSellingValue > 1 ? `${refinedSellingValue} ` : ''}${product.sellingUnit}`
            : product.isBundle
            ? ' / pack'
            : ' / pz';

    return (
        <div
            className={cx(wrapperClassName, {
                'mt-1 flex w-full flex-col-reverse md:flex-row md:items-end md:justify-between':
                    showProductQuantityForPrice,
            })}
        >
            <div className={cx(pricesWrapperClassName, 'mr-2 flex justify-start')}>
                {!hideDiscounts && regularPricePerUnit && discount ? (
                    <div className={cx(discountsWrapperClassName, 'flex items-center')}>
                        {/* REGULAR PRICE */}
                        <span
                            className={cx(
                                'mr-1',
                                'font-body leading-tight line-through',
                                'text-caption text-ui-gray-500'
                            )}
                        >
                            {money.formatMoney(regularPricePerUnit)}
                        </span>

                        {/* DISCOUNT % BADGE */}
                        <Badge type='discount'>{discount}</Badge>
                    </div>
                ) : context !== 'pos' && !hideDiscounts ? (
                    <div className='h-4' />
                ) : (
                    <></>
                )}

                <div className='flex flex-wrap items-center font-body text-normal'>
                    {/*TOTAL PRICE TO PAY*/}
                    {!hideDiscounts && isBuyingContext && (
                        <>
                            <span className='flex-shrink-0 text-sm font-body-bold text-ui-gray-900'>
                                {money.formatMoney(totalPriceToPay)}
                            </span>
                            <span className='mx-2 inline-block h-1 w-1 flex-shrink-0 rounded-full bg-brand-secondary-700' />
                        </>
                    )}
                    {/*PRICE WITH DISCOUNT*/}
                    <span
                        className={cx(
                            'flex-shrink-0',
                            isBuyingContext
                                ? 'leading-tight text-ui-gray-500'
                                : 'font-body-bold text-ui-gray-900',
                            context === 'hit' ? 'text-caption' : 'text-normal'
                        )}
                    >
                        {money.formatMoney(priceToPayPerUnit)}
                        {context !== 'default' && sellingPresentation}
                    </span>
                </div>
            </div>

            {/* QUANTITY IN PRODUCT PRESENTATION */}
            {showProductQuantityForPrice && (
                <div
                    className={cx(
                        'flex-shrink-0 font-body leading-tight md:flex md:items-end',
                        'text-left text-ui-gray-900 md:text-right',
                        context === 'product-page'
                            ? 'text-normal font-body-regular lg:flex-col'
                            : 'text-small font-body-regular'
                    )}
                >
                    {pricePerQuantityUnit && (
                        <span className='hidden text-caption font-body-regular text-ui-gray-500 lg:block'>
                            {pricePerQuantityUnit}
                        </span>
                    )}
                    <span className='text-caption text-black'>{productQuantityForPrice}</span>
                </div>
            )}
        </div>
    );
}
