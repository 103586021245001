import { useState, useCallback } from 'react';
/**
 * Hook to get element dimensions
 * @param {boolean} load
 *
 */
export default function useClientRect(load) {
    const [rect, setRect] = useState();

    const ref = useCallback(
        (el) => {
            if (el !== null) {
                setRect(el.getBoundingClientRect());
            }
        },
        [load]
    );
    return [rect, ref];
}
