import React, { useState, useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { removeAccentsLowerCase } from 'sf-modules';
import { SearchIcon } from 'icons/solid';

// Temporal component until refactor of ActionInput and Header is made.
const SearchInput = ({
    inputKey,
    icon,
    value,
    placeholder,
    onClick,
    onChange,
    className,
    disabled,
    style,
    removeAccents,
    upperCase,
    autoFocus,
    autocomplete = '',
    onKeyDown,
    withIcon,
    iconPosition,
    ...otherProps
}) => {
    const node = useRef();
    const [, setIsOpenedDropdown] = useState(false);

    const handleClickOutside = (event) => {
        if (node.current && !node.current.contains(event.target)) {
            setIsOpenedDropdown(false);
        }
    };

    const [values, setValues] = useState({
        name: value ? value : '',
        multiline: 'Controlled',
    });

    const action = (name) => (event) => {
        setValues({ ...values, [name]: event.target.value });
        if (onChange) onChange(event.target.value);
    };

    /**
     * On key down
     * @param e
     */
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') onClick(values.name);
        if (onKeyDown) onKeyDown(e);
    };

    useEffect(() => {
        typeof window !== 'undefined' &&
            document.addEventListener('click', handleClickOutside, false);
        return () => {
            typeof window !== 'undefined' &&
                document.removeEventListener('click', handleClickOutside, false);
        };
    }, []);

    useEffect(() => {
        if (value !== null && value !== undefined && removeAccents) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            value = removeAccentsLowerCase(value);
        }

        if (value !== null && value !== undefined && upperCase) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            value = value.toUpperCase();
        }

        setValues({
            name: value ? value : '',
            multiline: 'Controlled',
        });
    }, [value]);

    return (
        <div ref={node} className='flex h-inherit w-full' style={style} {...otherProps}>
            <div
                className={cx(
                    'input box-border flex w-full rounded-sm border border-ui-black bg-ui-white py-1.5 text-ui-black',
                    iconPosition === 'left' && 'flex-row-reverse',
                    iconPosition === 'right' && 'pl-4'
                )}
            >
                <input
                    type='text'
                    value={values.name}
                    className='w-full rounded-none border-0 p-0 font-body text-small leading-none outline-none ring-0 ring-transparent focus:ring-0'
                    id={inputKey}
                    key={inputKey}
                    name={inputKey}
                    placeholder={placeholder}
                    onChange={action('name')}
                    onKeyDown={handleKeyDown}
                    onClick={() => setIsOpenedDropdown(true)}
                    disabled={disabled}
                    title={placeholder}
                    autoFocus={autoFocus}
                    autoComplete={autocomplete}
                />

                {withIcon && (
                    <div
                        className='mx-2 flex cursor-pointer items-center justify-center'
                        onClick={() => onClick(values.name)}
                    >
                        <SearchIcon className='w-6' />
                    </div>
                )}
            </div>
        </div>
    );
};

SearchInput.displayName = 'SearchInput';

SearchInput.defaultProps = {
    disabled: false,
    removeAccents: false,
    upperCase: false,
    autoFocus: false,
    onClick: () => {},
    iconPosition: 'right',
};

SearchInput.propTypes = {
    inputKey: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    removeAccents: PropTypes.bool,
    upperCase: PropTypes.bool,
    autoFocus: PropTypes.bool,
    onClick: PropTypes.func,
    onKeyDown: PropTypes.func,
    iconPosition: PropTypes.oneOf(['right', 'left']),
};

export default SearchInput;
