import React from 'react';
import * as PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import cx from 'classnames';
import ModalHeader from '../../atoms/ModalHeader';
import ModalBody from '../../atoms/ModalBody';

const FullModal = ({
    onClose,
    isOpen,
    className,
    overlayClassName,
    headerBackgroundColor,
    headerClassName,
    headerShadow,
    style,
    title,
    children,
    headerContent,
    ...otherProps
}) => {
    const baseOverlayClass = cx(
        'fixed top-0 right-0 bottom-0 left-0 flex items-center justify-center pointer-events-auto w-full',
        'opacity-100 z-modal transition duration-200 ease-modal',
        [`${overlayClassName ? overlayClassName : 'bg-ui-white'}`]
    );
    const baseClass = cx(
        className,
        'max-w-4.5xl relative w-full md:py-20 flex h-full min-h-screen flex-col items-center justify-start'
    );

    return (
        <ReactModal
            overlayClassName={baseOverlayClass}
            className={baseClass}
            isOpen={isOpen}
            closeOnEscape={true}
            onRequestClose={onClose}
            ariaHideApp={false}
            {...otherProps}
        >
            <ModalHeader
                title={title}
                titleSize='text-h3 sm:text-h2 font-heading-regular'
                type='full'
                headerBackground={headerBackgroundColor ? headerBackgroundColor : 'bg-transparent'}
                onClose={onClose}
                containerClassName={cx(headerClassName, 'sm:mb-12 w-full', {
                    'shadow-down': headerShadow,
                })}
                children={headerContent}
            />

            {children && (
                <ModalBody className='w-full overflow-y-scroll px-4'>{children}</ModalBody>
            )}
        </ReactModal>
    );
};

FullModal.displayName = 'FullModal';

FullModal.defaultProps = {
    isOpen: false,
};

FullModal.propTypes = {
    onClose: PropTypes.func,
    isOpen: PropTypes.bool.isRequired,
    className: PropTypes.string,
    overlayClassName: PropTypes.string,
    headerBackgroundColor: PropTypes.string,
    headerClassName: PropTypes.string,
    headerShadow: PropTypes.bool,
    style: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    children: PropTypes.node.isRequired,
    headerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default FullModal;
