import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import Link from '../Link';

const HeaderItem = ({
    className,
    label,
    mobileLabel,
    href,
    prefetch,
    onClick,
    icon,
    withBadge,
    badgePosition,
    counter,
    withBorder,
}) => {
    return (
        <div className={cx('flex h-full items-center justify-center', className)}>
            <Link
                className={cx(
                    'flex h-full w-full flex-col items-center px-1 text-xxs xxs:text-caption md:px-2 xl:text-small'
                )}
                underline={false}
                href={href}
                label={label}
                onClick={onClick}
                prefetch={prefetch}
            >
                <>
                    {/* ICON */}
                    <div className='flex items-center justify-center text-ui-black'>
                        <div className='relative w-6 xl:w-6'>
                            {icon}

                            {(withBadge || (counter !== null && counter > 0)) && (
                                <span
                                    id='shopping-bag-items-counter'
                                    className='absolute mr-2 flex items-center justify-center rounded-xl bg-brand-primary-700 text-ui-white'
                                    style={{
                                        width: !counter ? '12px' : counter > 99 ? '18px' : '16px',
                                        height: !counter ? '12px' : counter > 99 ? '18px' : '16px',
                                        right: badgePosition && badgePosition.right, //'-14px',
                                        top: badgePosition && badgePosition.top, //'-8px',
                                        fontSize: counter > 99 ? '8px' : '9px',
                                        lineHeight: '10px',
                                    }}
                                >
                                    {counter > 99 ? '99+' : counter}
                                </span>
                            )}
                        </div>
                    </div>

                    {/* LABEL */}
                    <span className='hidden text-right lg:block'>{label}</span>
                    {mobileLabel && (
                        <div className='relative block text-center lg:hidden'>
                            <span className='border-ui-black text-right'>{mobileLabel}</span>
                        </div>
                    )}
                </>
            </Link>
            {withBorder && <span className='hidden h-8 w-px bg-ui-gray-500 md:block'></span>}
        </div>
    );
};

HeaderItem.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    mobileLabel: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.node.isRequired,
    withBadge: PropTypes.bool,
    badgePosition: PropTypes.object,
    counter: PropTypes.number,
    withBorder: PropTypes.bool,
};

HeaderItem.defaultProps = {
    withBadge: false,
};

export default HeaderItem;
