import React from 'react';
import NextImage from 'next/image';
import { imageTools } from 'sf-modules';

/**
 * Use our custom loader on Google Cloud Functions, linked to Google Storage
 * instead of next default loader
 */
const customLoader = ({ src, width, quality }) => {
    return imageTools.getOptimizedImageURL(src, {
        w: width,
        q: quality || 70,
    });
};

const Image = (props) => {
    if (!props || !props.src) return <></>;

    const imageLoader = props.src.startsWith('https://us-central1-yema-')
        ? { loader: customLoader } // YEMA CDN Loader
        : {};

    return <NextImage {...imageLoader} {...props} />;
};

export default Image;
