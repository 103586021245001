import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Transition, Dialog, Disclosure } from '@headlessui/react';
import { ArrowRightIcon } from 'icons/solid';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import Button from '../../atoms/Button';
import Image from '../../atoms/Image';
import ArrowDown from '../../assets/animations/ArrowDown';
import trolley from '../../../../assets/trolley.png';

const CategoriesLinkItem = ({ href, icon, label, name, lvl, onClick }) => {
    return (
        <li>
            <Button
                className='relative mr-auto flex w-full items-center justify-center gap-2 border-b border-ui-gray-300 px-3 py-4 text-h5 transition-all'
                href={href}
                underline={false}
                variants='custom'
                size='full'
                onClick={onClick}
            >
                {icon && <Image layout='fixed' width={14} height={14} src={icon} alt={label} />}
                <p
                    className={cx('mb-0 mr-auto font-body text-h5 font-body-normal', {
                        'font-body-bold': lvl === 0,
                    })}
                >
                    {name}
                </p>
            </Button>
        </li>
    );
};

const CategoriesButtonItem = ({
    lvl = 0,
    item, //aisles
    hasChild, //hasSubCat
    currentCategory,
    refinedMenu,
}) => {
    return (
        <div className='flex w-full items-center justify-start gap-2'>
            {(item.link === currentCategory.link ||
                (item.link === '/s' && currentCategory.link === '/s')) && (
                <ArrowRightIcon className='h-4 w-4 ' />
            )}

            {item.icon && (
                <Image layout='fixed' width={14} height={14} src={item.icon} alt={item.name} />
            )}

            <span
                className={cx(
                    'mb-0 font-body text-h5 font-body-normal',
                    {
                        'font-body-bold': lvl === 0,
                    },
                    item.link === currentCategory.link ? 'mr-0' : 'mr-auto'
                )}
            >
                {item.name}
            </span>
            {hasChild && (
                <ArrowDown className='ml-auto h-5 w-5' open={refinedMenu[lvl] === item.algolia} />
            )}
        </div>
    );
};

const CategoriesPanel = ({
    refinedMenu,
    setRefinedMenu,
    currentCategory,
    aisle,
    subCat,
    lvl = 1,
    handleCloseModal,
}) => {
    const handleToggleCategory = (algolia, hasChild) => {
        const refinedMenuCopy = [...refinedMenu];
        refinedMenuCopy[lvl] = refinedMenuCopy[lvl] === algolia ? '' : algolia;
        // if (refinedMenuCopy[lvl - 1] !== aisle.algolia || refinedMenuCopy[lvl - 1] === "") {
        if (!hasChild) handleCloseModal();
        setRefinedMenu(refinedMenuCopy);
    };

    if (!subCat || subCat.length <= 0) return '';

    return (
        // <ul className="pl-5 space-y-6 bg-brand-tertiary-100">
        <ul className='bg-brand-tertiary-100 pl-5'>
            {/* FIJO - CATEGORIES (Ej. "Todo Despensa") */}
            <CategoriesLinkItem
                href={aisle.link}
                label={`subcategoria-${lvl}-todo`}
                name={`Todo ${aisle.name}`}
                lvl={lvl}
                onClick={handleCloseModal}
            />

            {subCat &&
                subCat.map((subcategory, index) => {
                    const child = subcategory.subcategories;
                    const hasChild = child && child.length > 0;

                    return (
                        <Disclosure as='li' key={'subcategoria-' + index} className='pl-5'>
                            {({ open }) => (
                                <>
                                    <Disclosure.Button
                                        as={Button}
                                        className='relative mr-auto flex w-full border-b border-ui-gray-300 px-3 py-4 text-h5 transition-all'
                                        href={!hasChild ? subcategory.link : ''}
                                        underline={false}
                                        variants='custom'
                                        size='full'
                                        onClick={() =>
                                            handleToggleCategory(subcategory.algolia, hasChild)
                                        }
                                    >
                                        <CategoriesButtonItem
                                            className='pl-5'
                                            lvl={1}
                                            item={subcategory}
                                            hasChild={hasChild}
                                            currentCategory={currentCategory}
                                            refinedMenu={refinedMenu}
                                        />
                                    </Disclosure.Button>

                                    <Disclosure.Panel>
                                        <div className='grid grid-cols-1 items-start gap-y-10 gap-x-6'>
                                            {/* FIJO - SUBCATEGORIES  - TODO ALIMENTOS PREPARADOS  */}
                                            <CategoriesPanel
                                                refinedMenu={refinedMenu}
                                                setRefinedMenu={setRefinedMenu}
                                                currentCategory={currentCategory}
                                                aisle={subcategory}
                                                subCat={child}
                                                lvl={lvl + 1}
                                                handleCloseModal={handleCloseModal}
                                            />
                                        </div>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    );
                })}
        </ul>
    );
};

// AISLES
const AislesMenuMobile = ({
    aislesCategories,
    currentCategory,
    searchUrl,
    refinedMenu,
    setRefinedMenu,
    isPOS,
    navBarHeight,
}) => {
    const [open, setOpen] = useState(false);
    const { height } = useWindowDimensions();

    const posNavBarHeight = navBarHeight + 40;
    const calcViewportHeight = height - (isPOS ? posNavBarHeight : navBarHeight);
    const dropdownItemName =
        currentCategory && currentCategory.name ? `${currentCategory.name} ` : 'Pasillos';

    const handleClosePanel = () => {
        setTimeout(() => setOpen(false), 100);
    };

    const toogleMenuPanel = (itemPanelIsOpened) => {
        if (itemPanelIsOpened) setOpen(false);
        else setTimeout(() => setOpen(true), 100);
    };

    const handleToggleAisle = (algolia, hasSubCat) => {
        const refinedMenuCopy = [...refinedMenu];
        refinedMenuCopy[0] = refinedMenu[0] === algolia ? '' : algolia;
        setRefinedMenu(refinedMenuCopy);
        if (!hasSubCat) handleClosePanel();
    };

    if (aislesCategories && aislesCategories.length > 0) {
        let aislesPanelIsOpen = open;
        return (
            <div>
                <div className='z-40 flex h-full flex-1 items-center'>
                    <div
                        className='group flex h-full cursor-pointer items-center justify-center font-heading text-h5 text-ui-white'
                        onClick={() => toogleMenuPanel(aislesPanelIsOpen)}
                    >
                        <span>{dropdownItemName}</span>
                        <ArrowDown open={open} className='ml-2 h-5 w-5' />
                    </div>
                </div>
                {/* Mobile menu */}
                <Transition.Root show={open} as={Fragment}>
                    <Dialog as='div' className='relative' onClose={handleClosePanel}>
                        <div
                            className={cx('fixed z-100 flex h-screen w-full bg-ui-white', {
                                'max-w-md': isPOS,
                            })}
                            style={{
                                maxHeight: `${calcViewportHeight}px`,
                                top: `${isPOS ? posNavBarHeight : navBarHeight}px`,
                            }}
                        >
                            <Transition.Child
                                as={Fragment}
                                enter='transition ease-in-out transform'
                                enterFrom='-translate-y-6 opacity-0'
                                enterTo='translate-y-0 opacity-100'
                                leave='transition ease-in-out transform'
                                leaveFrom='translate-y-0 opacity-100'
                                leaveTo='-translate-y-6 opacity-0'
                            >
                                <Dialog.Panel
                                    as='ul'
                                    className='relative flex h-full w-full flex-col overflow-y-auto bg-ui-white pb-12 shadow-xl'
                                >
                                    {/*  FIJO - /s - TODOS LOS PRODUCTOS */}
                                    <CategoriesLinkItem
                                        href={searchUrl}
                                        icon={trolley}
                                        label='categoria-todos-productos'
                                        name='Todos los productos'
                                    />

                                    {aislesCategories.map((aisle, index) => {
                                        const subCat = aisle.categories;
                                        const hasSubCat = subCat !== undefined && subCat.length > 0;

                                        // PASILLOS - Comida - Bebés - Cuidado personal
                                        return (
                                            <Disclosure as='li' key={index}>
                                                {({ open }) => (
                                                    <>
                                                        <Disclosure.Button
                                                            as={Button}
                                                            className='items-left mr-auto flex w-full items-center justify-between gap-2 border-b border-ui-gray-300 px-3 py-4 text-h5 transition-all'
                                                            href={!hasSubCat ? aisle.link : ''}
                                                            underline={false}
                                                            variants='custom'
                                                            size='full'
                                                            onClick={() =>
                                                                handleToggleAisle(
                                                                    aisle.algolia,
                                                                    hasSubCat
                                                                )
                                                            }
                                                        >
                                                            <CategoriesButtonItem
                                                                item={aisle}
                                                                hasChild={hasSubCat}
                                                                currentCategory={currentCategory}
                                                                refinedMenu={refinedMenu}
                                                            />
                                                        </Disclosure.Button>

                                                        <Disclosure.Panel>
                                                            <div className='grid grid-cols-1 items-start gap-y-10 gap-x-6'>
                                                                <CategoriesPanel
                                                                    refinedMenu={refinedMenu}
                                                                    setRefinedMenu={setRefinedMenu}
                                                                    currentCategory={
                                                                        currentCategory
                                                                    }
                                                                    aisle={aisle}
                                                                    subCat={subCat}
                                                                    handleCloseModal={
                                                                        handleClosePanel
                                                                    }
                                                                />
                                                            </div>
                                                        </Disclosure.Panel>
                                                    </>
                                                )}
                                            </Disclosure>
                                        );
                                    })}
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>
            </div>
        );
    }
};

AislesMenuMobile.displayName = 'AislesMenuMobile';

AislesMenuMobile.defaultProps = {};

AislesMenuMobile.propTypes = {
    aislesCategories: PropTypes.array,
    currentCategory: PropTypes.object,
    searchUrl: PropTypes.string,
    isMobile: PropTypes.bool,
    refinedMenu: PropTypes.array,
    setRefinedMenu: PropTypes.func,
};

export default AislesMenuMobile;
