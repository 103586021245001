import React from 'react';
import * as PropTypes from 'prop-types';
import { IconsBrand as LogosBrandCollection } from './componentsMapping';

const LogosBrand = ({ type, className }) => {
    if (!type || typeof type !== 'string' || type.length === 0) return <></>;

    const LogoBrand = LogosBrandCollection[type] || LogosBrandCollection.horizontal_black;

    return <LogoBrand className={'max-h-16 ' + className} />;
};
LogosBrand.displayName = 'LogosBrand';

LogosBrand.propTypes = {
    type: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default LogosBrand;
