import dynamic from 'next/dynamic';

const YemaIcons = {
    horizontal_black: dynamic(() => import('icons/brand/YemaLogoHorizontalBlackIcon')),
    horizontal_white: dynamic(() => import('icons/brand/YemaLogoHorizontalWhiteIcon')),
    simple_black: dynamic(() => import('icons/brand/YemaLogoSimpleIcon')),
    simple_white: dynamic(() => import('icons/brand/YemaLogoSimpleWhiteIcon')),
    mundo_y: dynamic(() => import('icons/brand/YemaLogoMundoYIcon')),
    square_black: dynamic(() => import('icons/brand/YemaLogoSquareIcon')),
    square_white: dynamic(() => import('icons/brand/YemaLogoSquareWhiteIcon')),
};

const T3BIcons = {
    horizontal_black: dynamic(() => import('icons/brand/Logo3BIcon')),
    horizontal_white: dynamic(() => import('icons/brand/Logo3BIcon')),
    simple_black: dynamic(() => import('icons/brand/Logo3BSimpleIcon')),
    simple_white: dynamic(() => import('icons/brand/Logo3BSimpleIcon')),
    mundo_y: dynamic(() => import('icons/brand/Logo3BIcon')),
    square_black: dynamic(() => import('icons/brand/Logo3BSimpleIcon')),
    square_white: dynamic(() => import('icons/brand/Logo3BSimpleIcon')),
};

const isStorefront3B = process.env.NEXT_PUBLIC_CONTEXT === 'storefront_3b';
export const IconsBrand = isStorefront3B ? T3BIcons : YemaIcons;
