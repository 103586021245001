import React from 'react';
import * as PropTypes from 'prop-types';
import { get_, slugify } from 'sf-modules';
import Image from '../../atoms/Image/Image';
import Link from '../../atoms/Link';
import { SearchIcon } from 'icons/solid';
import IconCamera from '../../../../assets/icon-camera.svg';
import ProductPriceForQuantity from '../../atoms/Product/ProductPriceForQuantity';
import FullModal from '../../organisms/FullModal';

const CategoryLink = ({ searchValue, category, text, underline = false }) => (
    <Link
        key={`category-link-${category}-${text === 'Ver todos' ? 'todos' : 'category'}`}
        underline={underline}
        href={`/c/${slugify(category)}?q=${searchValue}`}
        className='font-heading text-small'
    >
        {text}
    </Link>
);

const LiProduct = React.memo(
    ({
        product,
        productName,
        setSearchValue,
        setShowSearchResults,
        onClickOnProduct,
        onResultClick,
        context,
    }) => {
        const withImage = product.thumbnail && product.thumbnail.length > 0;

        const LiContent = () => (
            <div className='flex w-full max-w-none items-center justify-start md:max-w-xs'>
                <div className='mr-3 flex items-center bg-ui-white'>
                    <Image
                        layout='fixed'
                        width={50}
                        height={50}
                        src={withImage ? product.thumbnail : IconCamera}
                        alt={withImage ? product.name : 'Producto en sesión de fotos'}
                        objectFit={withImage ? 'cover' : 'contain'}
                        objectPosition='center'
                    />
                </div>
                <div className='flex h-[50px] w-full flex-col justify-between p-0 '>
                    {context !== 'pos' ? (
                        ''
                    ) : context && get_(product, ['stock']) ? (
                        <div className='text-caption'>
                            <span className='text-ui-gray-500'>Stock: </span>
                            <span className='font-body-bold'>
                                {product.stock}
                                {product.availableStockUnit || 'pz'}
                            </span>
                        </div>
                    ) : (
                        <span className='text-caption'>No hay stock</span>
                    )}
                    <span
                        className='text-sm leading-4'
                        dangerouslySetInnerHTML={{ __html: productName }}
                    />
                    <div>
                        <ProductPriceForQuantity
                            wrapperClassName='!flex-row !items-start !justify-start gap-x-3'
                            context='hit'
                            product={product}
                            hideDiscounts
                        />
                    </div>
                </div>
            </div>
        );

        if (onResultClick && !get_(product, ['availability', 'itemsAvailable']))
            return (
                <li key={`product-search-${product.sku}`} className='p-2 opacity-40'>
                    <Link underline={false} style={{ cursor: 'not-allowed' }}>
                        <LiContent />
                    </Link>
                </li>
            );

        if (onResultClick)
            return (
                <li
                    key={`product-search-${product.sku}`}
                    className='my-1 p-2 transition duration-300 ease-in-out hover:scale-95 hover:bg-brand-primary-300'
                >
                    <Link
                        underline={false}
                        onClick={() => {
                            if (onClickOnProduct && typeof onClickOnProduct === 'function')
                                onClickOnProduct(product);
                            onResultClick(product);
                        }}
                    >
                        <LiContent />
                    </Link>
                </li>
            );

        return (
            <li
                key={`product-search-${product.sku}`}
                className='my-1 p-2 transition duration-300 ease-in-out hover:scale-95 hover:bg-brand-primary-300'
            >
                <Link
                    href={product.href}
                    underline={false}
                    onClick={() => {
                        if (onClickOnProduct && typeof onClickOnProduct === 'function')
                            onClickOnProduct(product);
                        if (setSearchValue) setSearchValue('');
                        setShowSearchResults(false);
                    }}
                >
                    <LiContent />
                </Link>
            </li>
        );
    }
);
LiProduct.displayName = 'LiProduct';

const highlight = (needle, haystack) =>
    haystack.replace(new RegExp(needle, 'gi'), (str) => `<strong>${str}</strong>`);

const SearchHeader = ({
    searchValue,
    searchUrl,
    setSearchValue,
    onClickSearch,
    setShowMobileSearchModal,
}) => {
    const handleKeyDown = () => {
        setShowMobileSearchModal(false);
        onClickSearch(`${searchUrl}?q=${searchValue}`);
    };

    return (
        <div className='relative w-full border-b border-ui-black pt-0 pb-1'>
            <SearchIcon className='absolute left-0 top-1/2 flex w-6 -translate-y-1/2 transform' />
            <input
                type='text'
                className='w-full border-0 bg-ui-white pt-2 pb-1 pl-8 pr-2 text-normal focus:border-ui-black focus:ring-0'
                value={searchValue}
                placeholder='Busca los mejores productos'
                onChange={(event) => setSearchValue(event.target.value)}
                id='mobilesearchinput'
                autoFocus={true}
                onKeyDown={(keyDown) =>
                    keyDown.key === 'Enter' && onClickSearch ? handleKeyDown() : ''
                }
                autoComplete='off'
                autoCorrect='off'
                autoCapitalize='off'
                spellCheck='false'
            />
        </div>
    );
};

const SearchedHitsComponent = ({
    searchValue,
    searchResults,
    setSearchValue,
    setShowSearchResults,
    onClickOnProduct,
    onResultClick,
    context,
}) => {
    const extractHits = (hits, nBHits) => {
        return hits.slice(0, nBHits).map((product, index) => {
            let productName =
                product.name.length > 30 ? product.name.substring(0, 30) + '...' : product.name;
            productName = highlight(searchValue, productName);

            return (
                <LiProduct
                    key={index}
                    product={product}
                    productName={productName}
                    setSearchValue={setSearchValue}
                    setShowSearchResults={setShowSearchResults}
                    onClickOnProduct={onClickOnProduct}
                    onResultClick={onResultClick}
                    context={context}
                />
            );
        });
    };
    const nbHits = Object.keys(searchResults).length > 1 ? 4 : 8;

    return Object.keys(searchResults).map((category) => {
        const objects = extractHits(searchResults[category], nbHits);

        return (
            <div className='col-span-1 w-full px-3' key={`search-category-column-c-${category}`}>
                <h4 className='mx-2 mt-0 mb-5 border-b border-black md:mb-1'>
                    <CategoryLink searchValue={searchValue} category={category} text={category} />
                </h4>

                {Object.keys(searchResults).length > 1 ? (
                    <ul className='md:flex-1'>{objects}</ul>
                ) : (
                    <>
                        <div className='my-2 md:mb-0 md:flex'>
                            <ul className='mb-2 md:flex-1'>
                                {objects.length > 4 ? objects.slice(0, 4) : objects}
                            </ul>
                            {objects.length > 4 && (
                                <ul className='mb-2 md:flex-1'>
                                    {objects.slice(4, objects.length)}
                                </ul>
                            )}
                        </div>

                        {category !== 'Otras categorías' && (
                            <CategoryLink
                                searchValue={searchValue}
                                category={category}
                                text='Ver todos'
                                underline={true}
                            />
                        )}
                    </>
                )}
            </div>
        );
    });
};

export default function SearchedHits({
    searchValue,
    setSearchValue,
    searchResults,
    setShowSearchResults,
    onClickOnProduct,
    onResultClick,
    context,
    showMobileSearchModal,
    setShowMobileSearchModal,
    onClickSearch,
    searchUrl,
    isInModal,
}) {
    if (isInModal)
        return (
            <FullModal
                onClose={() => setShowMobileSearchModal(false)}
                isOpen={showMobileSearchModal}
                overlayClassName={
                    !searchValue && searchValue.length === 0
                        ? 'bg-overlay-gray-100'
                        : 'bg-ui-gray-100'
                }
                headerBackgroundColor='bg-ui-white'
                headerShadow={true}
                headerContent={
                    <SearchHeader
                        searchValue={searchValue}
                        searchUrl={searchUrl}
                        setSearchValue={setSearchValue}
                        onClickSearch={onClickSearch}
                        setShowMobileSearchModal={setShowMobileSearchModal}
                    />
                }
            >
                {!searchValue && searchValue.length === 0 && (
                    <div className='mt-10 flex flex-col items-center'>
                        <span className='mb-3 flex h-11 w-11 items-center justify-center rounded-full border border-ui-gray-500'>
                            <SearchIcon className='block w-7 text-ui-gray-500' />
                        </span>
                        <p className='text-center font-body text-normal font-body-regular leading-snug text-ui-gray-500'>
                            Busca los mejores productos <br />
                            en YEMA
                        </p>
                    </div>
                )}

                {searchValue && searchValue.length > 0 && searchResults && (
                    <>
                        {Object.keys(searchResults).length > 0 ? (
                            <SearchedHitsComponent
                                searchValue={searchValue}
                                searchResults={searchResults}
                                setSearchValue={setSearchValue}
                                setShowSearchResults={setShowMobileSearchModal}
                                onClickOnProduct={onClickOnProduct}
                                onResultClick={onResultClick}
                                setShowMobileSearchModal={setShowMobileSearchModal}
                            />
                        ) : (
                            <div className='px-5'>
                                <p className='font-body text-normal font-body-regular text-ui-black'>
                                    No se encontraron productos con esos criterios
                                </p>
                            </div>
                        )}
                    </>
                )}
            </FullModal>
        );

    return (
        <SearchedHitsComponent
            searchValue={searchValue}
            searchResults={searchResults}
            setSearchValue={setSearchValue}
            setShowSearchResults={setShowSearchResults}
            onClickOnProduct={onClickOnProduct}
            onResultClick={onResultClick}
            context={context}
        />
    );
}

SearchedHits.defaultProps = {
    searchValue: '',
    searchResults: {},
    setSearchValue: () => {},
    setShowSearchResults: () => {},
    context: '',
    isInModal: false,
};

SearchedHits.propTypes = {
    searchValue: PropTypes.string,
    searchResults: PropTypes.object,
    setSearchValue: PropTypes.func,
    setShowSearchResults: PropTypes.func,
    onClickOnProduct: PropTypes.func,
    onResultClick: PropTypes.func,
    context: PropTypes.string,
};
