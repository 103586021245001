import React, { useEffect, useState, useRef } from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { sessionstorage, money } from 'sf-modules';
import {
    UserIcon,
    CartIcon,
    LocationUnknownIcon,
    LocationHomeIcon,
    SearchIcon,
    CloseIcon,
} from 'icons/solid';
import LogosBrand from '../../assets/logos/LogosBrand/LogosBrand';
import SearchInput from '../../atoms/SearchInput';
import Link from '../../atoms/Link';
import CategoryMenu from '../../molecules/CategoryMenu';
import SearchedHits from '../../molecules/SearchedHits';
import HeaderItem from '../../atoms/HeaderItem';
import PromoHeader from '../../molecules/PromoHeader';
import Tooltip from '../../molecules/Tooltip';

const HeaderMenu = ({
    setShowMobileSearchModal,
    onChangeDeliveryLocation,
    deliveryPostalCode,
    userLoggedIn,
    username,
    cartCount,
    cartTotal,
}) => {
    const splitUsername = username.split(' ');
    const parsedUsername = splitUsername.length > 1 ? splitUsername[0] : username;

    const [showCartTooltip, setShowCartTooltip] = useState(false);

    useEffect(() => {
        if (sessionstorage.get('y_synced_cart_alert')) {
            setShowCartTooltip(true);
        }
    }, [sessionstorage.get('y_synced_cart_alert')]);

    const isStorefront3B = process.env.NEXT_PUBLIC_CONTEXT === 'storefront_3b';

    return (
        <>
            {onChangeDeliveryLocation && (
                <Tooltip
                    uniqueId='no-delivery-postal-code-alert'
                    anchorClassName='order-2 w-12 md:order-1 md:w-16 lg:w-auto'
                    placement='bottom'
                    tooltipContent={
                        deliveryPostalCode
                            ? null
                            : 'Estás viendo todo nuestro catálogo, algunos productos dependen ' +
                              'de la dirección de entrega. Selecciona tu código postal para ver los ' +
                              'productos disponibles en tu zona.'
                    }
                >
                    <HeaderItem
                        label={`${
                            deliveryPostalCode
                                ? `Entrega a: ${deliveryPostalCode}`
                                : 'Dirección no especificada'
                        }`}
                        mobileLabel={deliveryPostalCode ? `${deliveryPostalCode}` : 'Sin CP'}
                        onClick={onChangeDeliveryLocation}
                        icon={
                            deliveryPostalCode ? (
                                <LocationHomeIcon className='h-6 w-6' />
                            ) : (
                                <LocationUnknownIcon className='h-6 w-6' />
                            )
                        }
                        withBadge={!deliveryPostalCode}
                        badgePosition={{ right: '-8px', top: '-2px' }}
                        withBorder={true}
                    />
                </Tooltip>
            )}

            {/* Search icon visible just in mobile  */}
            <HeaderItem
                className='order-4 w-12 md:hidden md:w-16 lg:w-auto'
                label='Buscar'
                onClick={() => setShowMobileSearchModal(true)}
                icon={<SearchIcon />}
                mobileLabel='Buscar'
            />

            {/* Logo visible just in mobile  */}
            <Link underline={false} href='/' label='Home' className='order-3 md:hidden'>
                <LogosBrand
                    type={isStorefront3B ? 'simple_black' : 'horizontal_black'}
                    className={cx('block w-full xxs:w-32 sm:w-36', isStorefront3B && 'h-12')}
                />
            </Link>

            <HeaderItem
                className='order-1 w-12 md:order-2 md:w-16 lg:w-auto'
                withBorder={true}
                label={
                    userLoggedIn
                        ? username && username !== ''
                            ? `¡Hola, ${parsedUsername}!`
                            : 'Mi cuenta'
                        : 'Cuenta'
                }
                mobileLabel={
                    userLoggedIn
                        ? username && username !== ''
                            ? parsedUsername
                            : 'Hola'
                        : 'Cuenta'
                }
                href={userLoggedIn ? '/cuenta' : '/cuenta/login'}
                prefetch={false}
                icon={<UserIcon className='h-6 w-6' />}
            />

            {showCartTooltip ? (
                <Tooltip
                    uniqueId='synced-cart'
                    anchorClassName='order-5'
                    tooltipBgColor='bg-brand-tertiary-500'
                    arrowFillColor='fill-brand-tertiary-500'
                    placement='bottom'
                    showOnHover={false}
                    tooltipContent={
                        <>
                            <p className='mb-1 flex items-center justify-between gap-x-2 font-body-bold'>
                                <div className='flex items-center gap-x-2'>
                                    <CartIcon className='h-6 w-6' />
                                    Tenías productos en otro carrito
                                </div>
                                <button
                                    className='hover:scale-105'
                                    type='button'
                                    onClick={() => {
                                        setShowCartTooltip(false);
                                        sessionstorage.remove('y_synced_cart_alert');
                                    }}
                                >
                                    <CloseIcon className='h-5 w-5' />
                                </button>
                            </p>
                            <p className='mb-0'>
                                Tienes abierta tu sesión en otro sitio con productos en tu carrito.
                                No te preocupes, los agregamos a este.
                            </p>
                        </>
                    }
                >
                    <HeaderItem
                        href='/carrito'
                        prefetch={false}
                        icon={<CartIcon className='h-6 w-6' />}
                        counter={cartCount}
                        label={cartTotal !== null ? money.formatMoney(cartTotal) : 'Mi carrito'}
                        mobileLabel={cartTotal !== null ? money.formatMoney(cartTotal) : 'Carrito'}
                        badgePosition={{ right: '-14px', top: '-8px' }}
                    />
                </Tooltip>
            ) : (
                <HeaderItem
                    className='order-5'
                    href='/carrito'
                    prefetch={false}
                    icon={<CartIcon className='h-6 w-6' />}
                    counter={cartCount}
                    label={cartTotal !== null ? money.formatMoney(cartTotal) : 'Mi carrito'}
                    mobileLabel={cartTotal !== null ? money.formatMoney(cartTotal) : 'Carrito'}
                    badgePosition={{ right: '-14px', top: '-8px' }}
                />
            )}
        </>
    );
};

const Header = ({
    fullHeader,
    userLoggedIn,
    username,
    cartCount,
    cartTotal,
    submenu,
    currentCategory,
    catalogSettings,
    onChangeDeliveryLocation,
    onClickSearch,
    refineResults,
    searchResults,
    isMobile,
    promoHeader,
    onClickOnProduct,
}) => {
    const [searchValue, setSearchValue] = useState('');
    const [showMobileSearchModal, setShowMobileSearchModal] = useState(false);
    const [showSearchResults, setShowSearchResults] = useState(false);

    const [navBarHeight, setNaviBarHeight] = useState(0);
    const navRef = useRef(null);

    const searchUrl = '/s';

    useEffect(() => {
        if (promoHeader) {
            setNaviBarHeight(navRef.current.clientHeight);
        }
    }, [promoHeader]);

    const handleGoSearch = (searchedValue) => {
        setSearchValue('');
        setShowSearchResults(false);
        onClickSearch(`${searchUrl}?q=${searchedValue}`);
    };

    useEffect(() => {
        if (refineResults) {
            const refineWithTimer = setTimeout(() => {
                refineResults(searchValue);
            }, 600);

            return () => {
                clearTimeout(refineWithTimer);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    useEffect(() => {
        if (
            !isMobile &&
            searchValue &&
            searchValue.length > 0 &&
            searchResults &&
            Object.keys(searchResults).length > 0
        ) {
            setShowSearchResults(true);
        }
    }, [searchValue, isMobile, searchResults]);

    useEffect(() => {
        const handleScroll = () => setShowSearchResults(false);

        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', handleScroll);
            // window.addEventListener('load', handleInitHeaderHeight);
        }

        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('scroll', handleScroll);
                // window.removeEventListener('load', handleInitHeaderHeight);
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {/* Full header wraps the menu and submenu */}
            {fullHeader ? (
                <>
                    <SearchedHits
                        {...{
                            isInModal: true,
                            showMobileSearchModal,
                            setShowMobileSearchModal,
                            searchValue,
                            setSearchValue,
                            onClickSearch: () => handleGoSearch(searchValue),
                            searchUrl,
                            searchResults,
                            onClickOnProduct: onClickOnProduct,
                        }}
                    />

                    <div
                        className='sticky top-0 left-0 z-[1000] w-full bg-ui-white shadow-md'
                        ref={navRef}
                    >
                        {/* PROMO HEADER */}
                        <PromoHeader promoInfo={promoHeader} />

                        <header id='header'>
                            <div className='relative h-15 pt-3 pb-2 md:h-auto md:px-6 md:pt-2 lg:px-8'>
                                {/* Header medium display when <= $breakpoint-mobile */}
                                <div className='flex w-full items-center justify-between'>
                                    {/* Logo visible just in desktop  */}
                                    <Link
                                        underline={false}
                                        href='/'
                                        label='Home'
                                        className='hidden md:block'
                                    >
                                        <LogosBrand
                                            type='horizontal_black'
                                            className='block w-48 lg:w-56'
                                        />
                                    </Link>

                                    {/* SearchInput visible just in desktop  */}
                                    <div className='hidden w-2/5 md:block'>
                                        {catalogSettings && catalogSettings.index && (
                                            <SearchInput
                                                inputKey='catalog-autocomplete-search-bar'
                                                onChange={(value) => setSearchValue(value)}
                                                onClick={() => handleGoSearch(searchValue)}
                                                value={searchValue}
                                                placeholder='Busca los mejores productos'
                                                withIcon={true}
                                                autocomplete='off'
                                                onKeyDown={(event) => {
                                                    // keyCode 27 = 'ESC' key
                                                    if (event.keyCode === 27) {
                                                        event.preventDefault();
                                                        setShowSearchResults(false);
                                                    }
                                                }}
                                            />
                                        )}
                                    </div>

                                    <div className='flex w-full items-center justify-between md:w-auto'>
                                        <HeaderMenu
                                            setShowMobileSearchModal={setShowMobileSearchModal}
                                            onChangeDeliveryLocation={onChangeDeliveryLocation}
                                            deliveryPostalCode={
                                                catalogSettings ? catalogSettings.zipCode : null
                                            }
                                            userLoggedIn={userLoggedIn}
                                            username={username}
                                            cartCount={cartCount}
                                            cartTotal={cartTotal}
                                        />
                                    </div>
                                </div>

                                {/*Searched hits*/}
                                {!isMobile &&
                                    showSearchResults &&
                                    Object.keys(searchResults).length > 0 && (
                                        <div
                                            // eslint-disable-next-line react/no-unknown-property
                                            before=''
                                            className='absolute left-0 z-100 h-[385px] w-full bg-brand-tertiary-100 
                                            py-5 before:absolute before:left-[calc(71.3%_-_50px)] before:top-[-8px]
                                            before:h-[14px] before:w-[14px] before:rotate-45 before:transform 
                                            before:border-t before:border-l before:border-ui-black before:bg-brand-tertiary-100 
                                            before:content-[attr(before)] md:top-[72px] lg:top-[81px] 
                                            before:lg:left-[calc(69.6%_-_50px)]'
                                        >
                                            <div className='lg:container'>
                                                <div className='flex'>
                                                    <SearchedHits
                                                        searchValue={searchValue}
                                                        searchResults={searchResults}
                                                        setSearchValue={setSearchValue}
                                                        setShowSearchResults={setShowSearchResults}
                                                        onClickOnProduct={onClickOnProduct}
                                                    />
                                                </div>
                                            </div>
                                            <CloseIcon
                                                className='absolute top-3.5 right-3.5 h-5 w-5 cursor-pointer hover:scale-110'
                                                onClick={() => {
                                                    setSearchValue('');
                                                    setShowSearchResults(false);
                                                }}
                                            />
                                        </div>
                                    )}
                            </div>
                        </header>

                        {submenu && submenu.length > 0 && (
                            <CategoryMenu
                                currentCategory={currentCategory}
                                menuCategories={submenu}
                                searchUrl={searchUrl}
                                navBarHeight={navBarHeight}
                            />
                        )}
                    </div>
                </>
            ) : (
                <>
                    <div
                        className='sticky top-0 left-0 z-[1000] w-full bg-ui-white shadow-md'
                        ref={navRef}
                    >
                        <header className='relative'>
                            <div className='relative flex h-15 w-full items-center justify-center px-2 pt-3 pb-2 md:h-16 md:px-6 md:pt-2 lg:px-8'>
                                <Link underline={false} href='/' label='Home'>
                                    <LogosBrand
                                        type='horizontal_black'
                                        className='block w-24 xxs:w-32 sm:w-36 md:w-40'
                                    />
                                </Link>
                            </div>
                        </header>
                    </div>
                </>
            )}
        </>
    );
};

Header.displayName = 'Header';

Header.defaultProps = {
    fullHeader: false,
    userLoggedIn: false,
    cartCount: 0,
    cartTotal: null,
    submenu: [],
    currentCategory: {},
};

Header.propTypes = {
    fullHeader: PropTypes.bool.isRequired,
    userLoggedIn: PropTypes.bool,
    username: PropTypes.string,
    cartCount: PropTypes.number,
    cartTotal: PropTypes.number,
    submenu: PropTypes.array,
    currentCategory: PropTypes.object,
    catalogSettings: PropTypes.object,
    onChangeDeliveryLocation: PropTypes.func,
};

export default React.memo(Header);
