import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import useMedia from 'use-media';
import { CloseIcon } from 'icons/solid';
import { links } from 'sf-modules';
import Image from '../../atoms/Image';
import styles from './PromoHeader.module.scss';

const PromoHeader = React.forwardRef(({ promoInfo, onClose }, ref) => {
    const isMobile = useMedia({ maxWidth: 640 });

    const { icon, text, background_color, text_color, link, published } = promoInfo || {};

    const containerClass = cx('relative w-full');
    const textClass = cx('w-full flex justify-center py-3', {
        'cursor-pointer': link && link.link,
        'items-center': !isMobile,
        'pl-6 pr-10 items-start': isMobile,
    });

    if (!published) return <div style={{ height: '42px' }} />;

    return (
        <div
            id='promoheader'
            ref={ref}
            className={containerClass}
            style={{
                zIndex: 9999999,
                backgroundColor: background_color,
                color: text_color,
            }}
        >
            <div
                className={cx(textClass, 'gap-x-1 sm:gap-x-3')}
                onClick={link.link ? links.handleRedirect(link.link) : null}
            >
                <Image layout='fixed' width={18} height={18} src={icon} alt='check' />
                <div
                    className={`m-0 text-center font-body text-small font-body-bold ${styles.text}`}
                    dangerouslySetInnerHTML={{
                        __html: text,
                    }}
                />
            </div>

            {onClose && (
                <div
                    className={cx('absolute top-0 right-0 mt-2 mb-px mr-5 cursor-pointer', {
                        'mr-3': isMobile,
                    })}
                    onClick={onClose}
                >
                    <CloseIcon className='h-5 w-5' />
                </div>
            )}
        </div>
    );
});

PromoHeader.propTypes = {
    promoInfo: PropTypes.shape({
        background_color: PropTypes.string,
        icon: PropTypes.string,
        published: PropTypes.bool,
        text: PropTypes.string,
        text_color: PropTypes.string,
    }),
    onClose: PropTypes.func,
};

export default PromoHeader;
