import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ChevronRightIcon } from 'icons/solid';
import Link from '../../atoms/Link';
import Image from '../../atoms/Image';
import useClientRect from '../../../../hooks/useClientRect';

// CATEGORIES ITEM
const CategoriesItem = React.memo(({ category, index, onClick, row }) => {
    const subCat = category.subcategories ? category.subcategories : category.categories;
    const [activeSubCat, setActiveSubCat] = useState(null);

    const handleHoverSubCat = (id) => setActiveSubCat(id);
    const handleLeaveSubCat = () => setActiveSubCat(null);

    const [load, loaded] = useState(false);
    const [rect, ref] = useClientRect(load);
    const [gridRows, setGridRows] = useState(1);

    useEffect(() => {
        if (category.link) loaded(true);
    }, [category.link]);

    useEffect(() => {
        rect && setGridRows(Math.ceil(rect.height / parseInt(row)));
    }, [rect]);

    return (
        <div className='w-full justify-self-center' style={{ gridRowEnd: `span ${gridRows}` }}>
            <div ref={ref}>
                {/* CATEGORY */}
                <Link
                    href={category.link}
                    onClick={onClick}
                    underline={false}
                    className='transition-color relative mb-0 mr-auto flex items-center px-2 py-1 font-body text-small font-body-bold duration-200 ease-easeInOutQuad hover:bg-brand-tertiary-700 hover:bg-opacity-10'
                    label={`categoria-${index}`}
                >
                    {category.icon && (
                        <Image
                            layout='fixed'
                            width={16}
                            height={16}
                            src={category.icon}
                            alt={category.name}
                        />
                    )}
                    <span className={cx({ 'ml-1': category.icon })}>{category.name}</span>
                </Link>

                {/* SUBCATEGORIES */}
                {subCat && (
                    <ul className='pb-2'>
                        {subCat.map((subCategory, i) => (
                            <li
                                key={'subcategoria-' + i}
                                className='group'
                                onMouseEnter={() => handleHoverSubCat(i)}
                                onMouseLeave={() => handleLeaveSubCat(i)}
                            >
                                <Link
                                    href={subCategory.link}
                                    onClick={onClick}
                                    underline={false}
                                    label={`subcategoria-${i}`}
                                    className={cx(
                                        'transition-color mb-0 flex bg-opacity-10 py-1 px-2 font-body text-small font-body-regular leading-snug text-ui-black duration-200 ease-easeInOutQuad',
                                        {
                                            'bg-brand-tertiary-700': i === activeSubCat,
                                        }
                                    )}
                                >
                                    {subCategory.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
});

// CATEGORIES & SUBCATEGORIES
const CategoriesMenu = ({ aisle, onCloseMenu }) => {
    const categories = aisle.categories;
    const defaultGridRow = 27;
    const columnWidth = 240;

    if (!categories || !categories.length) return <></>;

    return (
        <div className='absolute top-0 left-[216px] flex h-full max-h-full w-[calc(100%-216px)] flex-col justify-start space-y-2 overflow-auto bg-brand-tertiary-100 py-3 pl-2 pr-3 xl:pr-2'>
            {/* CATEGORIES TITLE - LINK */}
            <div className='flex items-center justify-between border-b border-ui-gray-300 px-2 pb-2'>
                <Link
                    underline={false}
                    href={aisle.link}
                    onClick={onCloseMenu}
                    className='mb-0 text-h4 font-heading-medium'
                >
                    {aisle.name}
                </Link>
                <Link
                    underline={false}
                    href={aisle.link}
                    onClick={onCloseMenu}
                    className='group-scope relative text-small'
                >
                    Ver todo {aisle.name}
                    <span className='group-scope-hover:scale-100 absolute bottom-0 left-0 hidden h-px w-full origin-left transform bg-ui-black transition duration-200 ease-out md:scale-0 lg:block' />
                </Link>
            </div>

            {/* CATEGORIES MENU */}
            <div
                className='m-0 grid h-[calc(100%-44px)] gap-0'
                style={{
                    gridTemplateColumns: `repeat(auto-fill, minmax(${columnWidth}px, 1fr))`,
                    gridAutoRows: `${defaultGridRow}px`,
                }}
            >
                {categories.map((category, index) => {
                    return (
                        <CategoriesItem
                            key={'categoria-' + index}
                            category={category}
                            index={index}
                            onClick={onCloseMenu}
                            row={defaultGridRow}
                        />
                    );
                })}
            </div>
        </div>
    );
};

const AislesMenuItem = React.memo(
    ({ index, aisle, activeAisle, handleToggleAisle, onCloseMenu }) => {
        return (
            <li
                onMouseEnter={() => handleToggleAisle(index)}
                onMouseLeave={() => handleToggleAisle(index)}
                className='group-scope'
            >
                <div
                    className={cx(
                        'group-scope-hover:bg-brand-tertiary-100 group-scope-hover:bg-opacity-100 relative flex items-center gap-2 px-3 py-2 transition-all duration-200 ease-easeInOutQuad',
                        {
                            'bg-brand-tertiary-100 bg-opacity-100': index === activeAisle,
                        }
                    )}
                >
                    <p className='mb-0 mr-auto font-body text-small font-body-bold'>{aisle.name}</p>

                    <ChevronRightIcon
                        className={cx(
                            'group-scope-hover:translate-x-1 mr-1 block w-6 transform transition-transform duration-200 ease-easeInOutQuad'
                        )}
                    />
                </div>

                {index === activeAisle && (
                    <CategoriesMenu aisle={aisle} onCloseMenu={onCloseMenu} />
                )}
            </li>
        );
    }
);

// AISLES
const AislesMenu = ({ aislesCategories, onCloseMenu }) => {
    const [activeAisle, setActiveAisle] = useState(0);
    const [isTouch, setIsTouch] = useState(false);

    const handleToggleAisle = (index) => setActiveAisle(index);

    useEffect(() => {
        if (typeof document !== 'undefined') {
            setIsTouch('ontouchstart' in document.documentElement);
        }
    }, []);

    if (!aislesCategories || !aislesCategories.length) return <></>;

    return (
        <div
            className={cx(
                'absolute top-[40px] left-[2rem] flex h-[70vh] max-h-[648px] w-[calc(100%-4rem)] transform overflow-auto shadow-down',
                {
                    'lg:h-[64vh]': isTouch,
                }
            )}
        >
            <ul className='mb-0 w-[216px] bg-brand-tertiary-500 py-4'>
                {aislesCategories.map((aisle, index) => {
                    return (
                        // PASILLOS
                        <AislesMenuItem
                            key={'categoria-' + index}
                            index={index}
                            aisle={aisle}
                            activeAisle={activeAisle}
                            handleToggleAisle={handleToggleAisle}
                            onCloseMenu={onCloseMenu}
                        />
                    );
                })}
            </ul>
        </div>
    );
};

AislesMenu.displayName = 'AislesMenu';
AislesMenuItem.displayName = 'AislesMenuItem';
CategoriesItem.displayName = 'CategoriesItem';

AislesMenu.defaultProps = {};

AislesMenu.propTypes = {
    aislesCategories: PropTypes.array,
    onCloseMenu: PropTypes.func,
};

export default AislesMenu;
