import * as PropTypes from 'prop-types';
import NextLink from 'next/link';
import cx from 'classnames';

const Link = ({ className, context, underline, children, ...props }) => {
    const href = props?.href;
    const rootClass = cx(className, 'cursor-pointer', {
        'relative inline-block underline': underline,
        'text-ui-error': context === 'danger',
    });

    if (!href || href === '') {
        const linkProps = {
            className: rootClass,
            'aria-label': props?.label || '',
            rel: props?.target === '_blank' ? 'noopener noreferrer' : '',
            ...props,
        };
        delete linkProps.shallow;
        return <a {...linkProps}>{children}</a>;
    }

    return (
        <NextLink className={rootClass} {...props}>
            {children}
        </NextLink>
    );
};

Link.defaultProps = {
    context: null,
    underline: true,
    target: '_self',
    shallow: false,
};

Link.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    context: PropTypes.oneOf(['', 'danger']),
    underline: PropTypes.bool,
    href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    prefetch: PropTypes.bool,
    target: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.string,
    shallow: PropTypes.bool,
    children: PropTypes.node,
};

export default Link;
