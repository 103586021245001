import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import useMedia from 'use-media';
import PropTypes from 'prop-types';
import { ChevronDownIcon } from 'icons/solid';
import useKeyPress from '../../../../hooks/useKeyPress';
import Link from '../../atoms/Link';
import Image from '../../atoms/Image';
import AislesMenuMobile from '../AislesMenuMobile';
import AislesMenu from '../AislesMenu';

// NAVBAR CONTAINER
const NavItem = React.memo(
    ({ className, icon, label, name, children, href, onClick, onMouseEnter, onMouseLeave }) => {
        return (
            <li
                className={cx(className, 'group flex w-auto items-center justify-center')}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <Link
                    className={cx(
                        'group flex h-full items-center justify-center p-1 text-ui-white transition-all duration-300'
                    )}
                    underline={false}
                    label={label}
                    onClick={onClick}
                    href={href ? href : null}
                >
                    {icon && <Image layout='fixed' width={16} height={16} src={icon} alt={name} />}

                    <p
                        className={cx('mb-0 font-heading text-h4 font-heading-medium', {
                            'ml-3.5': icon,
                        })}
                    >
                        {name}
                    </p>

                    {children && (
                        <ChevronDownIcon className='ml-4 w-6 transform text-ui-white transition-transform duration-200 ease-easeInOutQuad group-hover:translate-y-0.5' />
                    )}
                </Link>

                {children}
            </li>
        );
    }
);

// *** RENDER COMPONENT
const CategoryMenu = ({
    currentCategory,
    menuCategories,
    searchUrl,
    isPOS,
    backgroundCustom,
    navBarHeight,
}) => {
    const isMobile = useMedia({ maxWidth: 1023 });

    const mainMenu = !isPOS && menuCategories.filter((f) => !f.algolia.includes('Pasillos'));
    const aislesMenu = !isPOS && menuCategories.filter((f) => f.algolia.includes('Pasillos'));

    const [refinedMenu, setRefinedMenu] = useState(['', '', '']);
    const [openMenu, setOpenMenu] = useState(false);

    const handleHoverMenu = () => {
        setOpenMenu(true);
    };
    const handleLeaveMenu = () => {
        setOpenMenu(false);
    };

    useKeyPress('Escape', () => handleLeaveMenu());

    useEffect(() => {
        // const bodyElement = typeof window !== 'undefined' ? document.body : undefined;
        // if (typeof bodyElement !== 'undefined' && bodyElement !== null)
        //     bodyElement.style.overflow = 'auto';
        // Auto open current category menu
        if (currentCategory && currentCategory.algolia) {
            const levels = currentCategory.algolia.split(' > ');
            const level1 = levels.length >= 1 ? levels[0] : '';
            const level2 = levels.length >= 2 ? `${levels[0]} > ${levels[1]}` : '';
            const level3 = levels.length >= 3 ? currentCategory.algolia : '';
            setRefinedMenu([level1, level2, level3]);
        }
    }, [currentCategory]);

    return (
        <nav
            className={cx('navbar h-11 py-0 lg:h-10', [
                `${backgroundCustom ? backgroundCustom : 'bg-navbar'}`,
            ])}
        >
            <ul className='navbar-nav relative mb-0 flex h-full max-w-full justify-center lg:justify-start xl:px-8'>
                {menuCategories && menuCategories.length && (
                    <>
                        {isMobile || isPOS ? (
                            <AislesMenuMobile
                                aislesCategories={menuCategories}
                                currentCategory={currentCategory}
                                searchUrl={searchUrl}
                                refinedMenu={refinedMenu}
                                setRefinedMenu={setRefinedMenu}
                                isMobile={isMobile}
                                isPOS={isPOS}
                                navBarHeight={navBarHeight}
                            />
                        ) : (
                            <NavItem
                                name='Pasillos'
                                className='w-full lg:w-[216px]'
                                label='pasillo-todos-link'
                                onClick={() => setOpenMenu(!openMenu)}
                                onMouseEnter={handleHoverMenu}
                                onMouseLeave={handleLeaveMenu}
                            >
                                <>
                                    {openMenu && (
                                        <AislesMenu
                                            aislesCategories={aislesMenu}
                                            onCloseMenu={() => setOpenMenu(!openMenu)}
                                        />
                                    )}
                                </>
                            </NavItem>
                        )}
                    </>
                )}

                {/* ONLY DESKTOP */}
                {!isPOS &&
                    mainMenu.map((item, i) => {
                        return (
                            <NavItem
                                key={`pasillo-` + i}
                                name={item.name}
                                label={`pasillo-` + i}
                                icon={item.icon}
                                href={item.link}
                                className='hidden px-5 lg:block'
                            />
                        );
                    })}
            </ul>
        </nav>
    );
};

CategoryMenu.displayName = 'CategoryMenu';
NavItem.displayName = 'NavItem';

CategoryMenu.defaultProps = {};

CategoryMenu.propTypes = {
    currentCategory: PropTypes.object,
    menuCategories: PropTypes.array,
    searchUrl: PropTypes.string,
};

export default CategoryMenu;
