import PropTypes from 'prop-types';
import { ChevronDownIcon } from 'icons/solid';
import cx from 'classnames';

const ArrowDown = ({ open, className }) => {
    return (
        <ChevronDownIcon
            className={cx(className, 'shrink-0 transition duration-300 ease-in-out', {
                'h-5 w-5': !className,
                'rotate-x-180': open,
            })}
        />
    );
};

ArrowDown.defaultProps = {};

ArrowDown.propTypes = {
    open: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default ArrowDown;
